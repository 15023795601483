

// FormComponent.js
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './FormComponent.css';
import { useParams } from 'react-router-dom';
import Axios from 'axios'
import SERVER_URL from './components/URL';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import secretKey from './components/Secret.js';

const FormComponent = () => {

 

const {UIDToken} = useParams()

const bytes = CryptoJS.AES.decrypt(decodeURIComponent(UIDToken), secretKey);

  const UID = bytes.toString(CryptoJS.enc.Utf8);

console.log(UID)


const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  gender: Yup.string().required('Gender is required'),
  userType: Yup.array().min(1, 'Select at least one user type'),
  dateOfBirth: Yup.date().required('Date of Birth is required'),
  addressType: Yup.array().min(1, 'Select at least one address type'),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  addressLine2: Yup.string(),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  pincode: Yup.string().required('Pincode is required'),
});



  return (
    <div className="form-container">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          gender: '',
          userType: [],
          dateOfBirth: '',
          addressType: [],
          addressLine1: '',
          addressLine2: '',
          state: '',
          city: '',
          pincode: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);

          Axios.post(`${SERVER_URL}/Details`,{values , UID}).then(function(output)
        {
            const { status, message, redirectUrl } = output.data;
            alert(message);
            window.location.href = redirectUrl;
        }).catch(function(error)
        {
             console.error(error)
        })
        }}
      >
        {() => (
          <Form className="form">
            <h1>Registration Form</h1>

            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <Field name="firstName" type="text" className="form-control" />
              <ErrorMessage name="firstName" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <Field name="lastName" type="text" className="form-control" />
              <ErrorMessage name="lastName" component="div" className="error" />
            </div>

            <div className="form-group">
              <label>Gender</label>
              <div role="group" className="checkbox-group">
                <label>
                  <Field type="radio" name="gender" value="Male" />
                  Male
                </label>
                <label>
                  <Field type="radio" name="gender" value="Female" />
                  Female
                </label>
                <label>
                  <Field type="radio" name="gender" value="Not to disclose" />
                  Not to disclose
                </label>
              </div>
              <ErrorMessage name="gender" component="div" className="error" />
            </div>

            <div className="form-group">
              <label>User Type</label>
              <div role="group" className="checkbox-group">
                {/* <label>
                  <Field type="checkbox" name="userType" value="Employer" />
                  Employer
                </label>
                <label>
                  <Field type="checkbox" name="userType" value="Employee" />
                  Employee
                </label> */}
                {/* <label>
                  <Field type="checkbox" name="userType" value="Educational Institutes" disabled />
                  Educational Institutes
                </label> */}
                <label>
                  <Field type="checkbox" name="userType" value="Print Designer" />
                  Print Designer
                </label>
                <label>
                  <Field type="checkbox" name="userType" value="Tee Manufacturer" />
                  Tee Manufacturer
                </label>
                <label>
                  <Field type="checkbox" name="userType" value="Buyer" />
                  Buyer
                </label>
                <label>
                  <Field type="checkbox" name="userType" value="Shipping Company" />
                  Shipping Company
                </label>
              </div>
              <ErrorMessage name="userType" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="dateOfBirth">Date of Birth</label>
              <Field name="dateOfBirth" type="date" className="form-control" />
              <ErrorMessage name="dateOfBirth" component="div" className="error" />
            </div>

            <div className="form-group">
              <label>Address Type</label>
              <div role="group" className="checkbox-group">
                <label>
                  <Field type="checkbox" name="addressType" value="Permanent" />
                  Permanent
                </label>
                <label>
                  <Field type="checkbox" name="addressType" value="Communication" />
                  Communication
                </label>
                <label>
                  <Field type="checkbox" name="addressType" value="Work/Office" />
                  Work/Office
                </label>
                <label>
                  <Field type="checkbox" name="addressType" value="Others" />
                  Others
                </label>
              </div>
              <ErrorMessage name="addressType" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="addressLine1">Address Line 1</label>
              <Field name="addressLine1" type="text" className="form-control" />
              <ErrorMessage name="addressLine1" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="addressLine2">Address Line 2</label>
              <Field name="addressLine2" type="text" className="form-control" />
            </div>

            <div className="form-group">
              <label htmlFor="state">State</label>
              <Field name="state" type="text" className="form-control" />
              <ErrorMessage name="state" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="city">City</label>
              <Field name="city" type="text" className="form-control" />
              <ErrorMessage name="city" component="div" className="error" />
            </div>

            <div className="form-group">
              <label htmlFor="pincode">Pincode</label>
              <Field name="pincode" type="text" className="form-control" />
              <ErrorMessage name="pincode" component="div" className="error" />
            </div>

            <button type="submit" className="submit-button">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FormComponent;
