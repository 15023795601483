



import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './styles.css';
import URLShortener from './components/URLShortener';
import Carousel from './components/Carousel';
import Footer from './components/Footer';

import ContactPage from './pages/ContactPage';
import Login from './Login';
import Register from './Register';
import Home from './BusinessLogic/Home';
import PageTracking from './usePageTracking'; // Import PageTracking component
import Callback from './Callback';
import Forgot from './Forgot';
import ResetPassword from './ResetPassword';
import MobileOTP from './MobileOTP';

import { createContext } from 'react';
import EmailLogin from './Emaillogin';
import Navbar from './Navbar';
import Products from './BusinessLogic/Products';
import FormComponent from './Details';


export const MyBox = createContext();

const initializeGA4 = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-BE9F32NMX5`; // Replace 'G-XXXXXXXXXX' with your GA4 Measurement ID
  script.async = true;
  document.head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  window.gtag = gtag; // Ensure gtag is globally accessible

  gtag('js', new Date());
  gtag('config', 'G-BE9F32NMX5'); // Replace 'G-XXXXXXXXXX' with your GA4 Measurement ID
};

function App() {
  useEffect(() => {
    initializeGA4();
  }, []);

  const [Disappear, setDisappear] = React.useState(false);

  

  // useEffect(() => {

  //   localStorage.setItem('Loginbox', JSON.stringify(true));
  //   // Logindata(false); // Update the context state
  // }, []);

  return (
    <Router>
      <PageTracking /> {/* Ensure PageTracking is within Router */}
      {!Disappear ? null : <Navbar   />}
      <div className="app-container">

     {!Disappear ?    <div className="logo-container">
          <img src="/logo.png" alt="logo" className="logo" /> {/* Ensure the path is correct */}
        </div> : null}

        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <Carousel />
                <URLShortener />
              </React.Fragment>
            }
          />
          <Route path="/urlshortner" element={<URLShortener />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path="/login" element={<Login  data={setDisappear} />}>    
            
          </Route>

          <Route path='/navbar' element = {<Navbar data={setDisappear} />}  ></Route>       
          <Route path="/login/Email/:nameToken" element={<EmailLogin  />} />
          <Route path="/login/OTP/:numberToken" element={<MobileOTP />} />

          <Route path='/products' element = {<Products/>}  ></Route>

          <Route path = '/details/:UIDToken' element = {<FormComponent/>} ></Route>
         
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home data={setDisappear} />} />
          <Route path="/callback" element={<Callback />} />
          <Route path= '/forgot' element={<Forgot />} />
          <Route path='/reset/:token' element={<ResetPassword />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
