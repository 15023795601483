// src/PageTracking.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pagePath = location.pathname + location.search;

    // Check if window.gtag is defined and callable
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: pagePath,
      });

      // Track page view with Google Tag Manager if needed
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'pageview',
          page_path: pagePath,
        });
      }
    } else {
      console.warn('window.gtag is not initialized or available.');
    }
  }, [location]);

  return null;
};

export default PageTracking;
