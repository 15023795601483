import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { auth } from './Firebase'; // Adjust path as necessary
import SERVER_URL from './components/URL';

const linkedInClientId = '86twjp6r64jxz2';
const linkedInClientSecret = '7773s6PrdVuBK9bh';
const linkedInRedirectUri = 'https://4n.eco/callback';

const Callback = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getLinkedInAccessToken = async (code) => {
      const url = `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${code}&redirect_uri=${encodeURIComponent(linkedInRedirectUri)}&client_id=${linkedInClientId}&client_secret=${linkedInClientSecret}`;
      const response = await axios.post(url, {}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      return response.data.access_token;
    };

    const fetchLinkedInProfile = async (accessToken) => {
      const profileUrl = 'https://api.linkedin.com/v2/me';
      const emailUrl = 'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))';
      const [profileResponse, emailResponse] = await Promise.all([
        axios.get(profileUrl, { headers: { Authorization: `Bearer ${accessToken}` } }),
        axios.get(emailUrl, { headers: { Authorization: `Bearer ${accessToken}` } }),
      ]);
      return { profile: profileResponse.data, email: emailResponse.data.elements[0]['handle~'].emailAddress };
    };

    const authenticateWithFirebase = async (linkedinProfile) => {
      // Replace this with your backend endpoint to create custom token
      const customTokenEndpoint = `${SERVER_URL}/createCustomToken`;
      const response = await axios.post(customTokenEndpoint, linkedinProfile);
      return response.data.token;
    };

    const handleLinkedInCallback = async () => {
      const code = new URLSearchParams(location.search).get('code');
      if (code) {
        try {
          const accessToken = await getLinkedInAccessToken(code);
          const linkedinProfile = await fetchLinkedInProfile(accessToken);
          const customToken = await authenticateWithFirebase(linkedinProfile);
          await signInWithCustomToken(auth, customToken);
          navigate('/home'); // Use navigate instead of history.push
        } catch (error) {
          console.error('LinkedIn sign-in error:', error);
        }
      }
    };

    handleLinkedInCallback();
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default Callback;



// import React, { useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { getAuth, signInWithCustomToken } from 'firebase/auth';
// import { auth } from './Firebase'; // Adjust path as necessary

// const linkedInClientId = '86twjp6r64jxz2';
// const linkedInClientSecret = '7773s6PrdVuBK9bh';
// const linkedInRedirectUri = 'http://localhost:3000/callback';

// const Callback = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

 
//   useEffect(() => {
//     const handleLinkedInCallback = async () => {
//       const code = new URLSearchParams(location.search).get('code');
//       if (code) {
//         try {
//           const response = await axios.post('http://localhost:5000/login-with-linkedin', { code });
//           const { token } = response.data;
//           console.log(token)
//           await signInWithCustomToken(auth, token);
//         //   navigate('/home');
//         window.location.pathname = '/home'
//         } catch (error) {
//           console.error('LinkedIn sign-in error:', error);
//         }
//       }
//     };

//     handleLinkedInCallback();
//   }, [location, navigate]);


//   return <div>Loading...</div>;
// };

// export default Callback;
