import React, { useState } from 'react';
import Axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SERVER_URL from './components/URL';
import './Forgot.css'; // Make sure to import the CSS file

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await Axios.post(`${SERVER_URL}/users/forgetPassword`, { email });

      console.log(response)

      if (response.status === 200) {
        toast.success('Password reset link sent to your email.');
      } else {
        toast.error('Failed to send password reset link.');
      }
    } catch (error) {
      toast.error('An error occurred while sending the reset link. Please try again.');
      console.error('Error during password reset request:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-container">
      <div className="forgot-box">
        <h2>Forgot Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className='forgot-email'
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Sending...' : 'Send Reset Link'}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Forgot;
