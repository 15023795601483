import React, { useEffect, useContext, useState } from 'react';
import './Email.css';
import SERVER_URL from './components/URL';
import Axios from 'axios';
import { useParams , Link, useSearchParams } from 'react-router-dom';
import { LoginForm } from './Login';
import CryptoJS from 'crypto-js';
import secretKey from './components/Secret.js';



function EmailLogin() {


  
  const {nameToken} = useParams();


  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(nameToken), secretKey);

  const name = bytes.toString(CryptoJS.enc.Utf8);

  console.log(name)

  // const { Logindata } = useContext(LoginForm);

  // Logindata(false)

  // const Loginbox = JSON.parse(localStorage.getItem('LoginBox'))

  // useEffect(() => {

  //   localStorage.setItem('Loginbox', JSON.stringify(false));
  //   // Logindata(false); // Update the context state
  // }, []);

  const [appear, setAppear] = useState(false);
  const [collect, setCollect] = useState('');
  const [message, setMessage] = useState('');

  const collectPassword = (event) => {
    setCollect(event.target.value);
  };

  const toggleConfirmPasswordVisibility = () => {
    setAppear(!appear);
  };

  const fetchDetails = (event) => {
    event.preventDefault();
    Axios.post(`${SERVER_URL}/Login`, { Collect: collect, name })
      .then(output => {
        const { message, redirectUrl } = output.data;
        setMessage(message);
        
        if (redirectUrl) window.location.href = redirectUrl;
      })
      .catch(error => {
        console.error('Error during login request:', error);
        setMessage(error.response?.data?.message || 'An error occurred');
      });
  };

  return (
    <div className="email-container">
      <form onSubmit={fetchDetails}>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <div className="togethers">
            <input
              type={appear ? 'text' : 'password'}
              id="Email-password"
              name="Password"
              required
              onChange={collectPassword}
            />
            <span onClick={toggleConfirmPasswordVisibility}>
              <i id="eyes" style={{ color: appear ? 'red' : 'gray' }} className="fa fa-eye"></i>
            </span>
          </div>
        </div>
        <div className="forgot-password">
          <Link to='/forgot'>Forgot Password?</Link>
        </div>
        <button type="submit" className="login-btn">Login</button>
      </form>
    </div>
  );
}

export default EmailLogin;
