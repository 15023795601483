import React, { useState } from 'react';
import './Home.css';
import Products from './Products';

function Home(props) {
  
  props.data(true);

  const [ShowProducts, setShowProducts] = useState(false);

  function ScrolltoBottom() {
    window.scrollTo({
      top: 1500,
      behavior: 'smooth'
    });
  }

  

  return (
    <div>
      <div className='ImageContainer'>
        <img className='Firstbanner' src='/images/first.jpg' alt='Banner' />
        <div id='Content'>
          <h1 style={{color : 'gold'}}  >TEEEVO</h1>
          <p>MONETIZE YOUR QRIOUSITY. DESIGN. SELL. REPEAT</p>
          <button type="button" className="btn btn-light">Start Creating</button>
        </div>
      </div>

      <div className='Imagecontainer2'>
        <img className='Secondbanner' src='/images/second.jpg' alt='Second Banner' />

        <div className='content2'>
          <h4>THE MARKET PLACE FOR INSPIRED CREATORS AND UNIQUE CREATIONS</h4>
          <button onClick={ScrolltoBottom} type="button" className="btn btn-danger">SHOP NOW</button>
        </div>

        {/* <div className='Bottombox'>
          <h4>Want to create and sell your own products? Get started on Spring</h4>
          <button type="button" id='bottombutton' className="btn btn-light">Start Creating</button>
        </div> */}



      </div>

      {/* Display products for different categories */}
      <Products gender="Male" />
      <Products gender="Female" />
      <Products gender="Unisex" />
    </div>
  );
}

export default Home;
