import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Reset.css';
import { useParams } from "react-router-dom";
import SERVER_URL from "./components/URL";
import React, { useState } from "react";
import { useCookies } from 'react-cookie';

const ResetPassword = () => {
    const { token } = useParams();
    const [appear, setAppear] = useState(false);

    const [cookies, setCookie] = useCookies(['resetToken']); // Initialize cookies




    React.useEffect(() => {
        // Store token in cookie when component mounts
        setCookie('resetToken', token, {
            path: '/', // Make cookie available across the entire site
            maxAge: 10 * 60 * 1000, // 10 minutes
            sameSite: 'strict', // Restrict cookie to same-site requests
            // secure: process.env.NODE_ENV === 'production', // Send cookie over HTTPS only in production
        });
    }, [token, setCookie]);






    const toggleConfirmPasswordVisibility = () => {
        setAppear(!appear);
    };


console.log("Token front end",token)



    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required("Required")
                .min(8, "Password must be at least 8 characters.")
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
                    "Must include upper, lower, number, special character."
                ),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const { newPassword } = values;


            Axios.post(`${SERVER_URL}/Users/reset-password/${encodeURIComponent(token)}`, { newPassword }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            Axios.post(`${SERVER_URL}/Users/reset-password`, { newPassword , token })

                .then((response) => {
                    toast.success(response.data.message);
                    setTimeout(() => {
                        window.location.href = "/login"; // Redirect to login page after successful password reset
                    }, 3000); // Redirect after 3 seconds
                })
                .catch((error) => {
                    
                    toast.error("Your link has expired or an error occurred.");
                })
                .finally(() => {
                    setSubmitting(false); // Reset submitting state
                });
        },
    });

    // Function to show tick mark if passwords match
    const showTickMark = () => {
        return formik.values.confirmPassword && formik.values.newPassword === formik.values.confirmPassword;
    };

    return (
        <div className="reset-password-container">
            <h2>Reset Password</h2>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <div className="TOgether">
                        <input
                            type={appear ? 'text' : 'password'}
                            id="newPassword"
                            name="newPassword"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <span onClick={toggleConfirmPasswordVisibility}>
                            <i id="eyed" style={{ color: appear ? 'red' : 'gray' }} className="fa fa-eye"></i>
                        </span>
                    </div>
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                        <div className="error-message">{formik.errors.newPassword}</div>
                    ) : null}
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="TOgether">
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {showTickMark() && <i className="fa fa-check" id='tick' style={{ color: 'green' }} ></i>}
                    </div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                        <div className="error-message">{formik.errors.confirmPassword}</div>
                    ) : null}
                </div>

                <button type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? 'Submitting...' : 'Reset Password'}
                </button>
            </form>
        </div>
    );
};

export default ResetPassword;


