// import React, { useState } from 'react';
// import { RecaptchaVerifier, signInWithPhoneNumber, auth } from './Firebase'; // Adjust Firebase imports as per your setup
// import { useParams } from 'react-router-dom';
// import './Mobile.css'
// import { useContext } from 'react'
// import { MyBox } from './App'
// import { LoginForm } from './Login';


// function MobileOTP() {
//   const [message, setMessage] = useState('');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [recaptchaVerified, setRecaptchaVerified] = useState(false);
//   const [confirmationResult, setConfirmationResult] = useState(null);
//   const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);


 


//   const { number } = useParams(); // Assuming `Mobile` contains the phone number from useParams

//   let recaptchaVerifier;

//   const setUpRecaptcha = () => {
//     if (!recaptchaVerifier) {
//       recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
//         size: 'normal',
//         callback: () => {
//           setRecaptchaVerified(true);
//           setShowVerificationCodeInput(true); // Show verification code input when reCAPTCHA is verified
//           console.log('reCAPTCHA solved');
//         },
//         'expired-callback': () => {
//           setRecaptchaVerified(false);
//           console.log('reCAPTCHA expired');
//         }
//       });
//     }
//   };

//   const handleSignInWithPhone = (phoneNumber) => {
//     console.log('Handling sign in with phone:', phoneNumber);
//     setUpRecaptcha();
//     const appVerifier = recaptchaVerifier;

//     signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
//       .then((result) => {
//         setConfirmationResult(result);
//         console.log('SMS sent, confirmation result:', result);
//         setMessage('Verification code sent.');
//       })
//       .catch((error) => {
//         console.error('Error during phone login request:', error);
//         setMessage(error.message);
//       });
//   };

//   const handleVerifyCode = () => {
//     console.log('Verifying code:', verificationCode);
//     if (confirmationResult) {
//       confirmationResult.confirm(verificationCode)
//         .then((result) => {
//           console.log('User signed in successfully:', result.user);
//           setMessage('User signed in successfully.');
//           // Redirect or perform further actions upon successful verification
//           window.location.pathname = '/home';
//         })
//         .catch((error) => {
//           console.error('Error verifying code:', error);
//           setMessage(error.message);
//         });
//     } else {
//       setMessage('No confirmation result available. Please resend the verification code.');
//     }
//   };

//   return (
//     <div className="container">
//       <div className="row justify-content-center mt-5">
//         <div className="col-md-6">
//           <div className="card">
//             <div className="card-body">
//               <h3 className="card-title text-center mb-4">Verify Phone Number</h3>
//               <form>
//                 {!showVerificationCodeInput && (
//                   <div className="form-group" id="recaptcha-container"></div>
//                 )}
//                 {!showVerificationCodeInput ? (
//                   <button
//                     type="button"
//                     className="btn btn-primary btn-block mb-3"
//                     onClick={() => handleSignInWithPhone(number)}
//                     disabled={recaptchaVerified}
                    
//                   >
//                     {!recaptchaVerified ? 'Send Verification Code' : 'Resend Verification Code'}
//                   </button>
//                 ) : (
//                   <div className="form-group">
//                     <label htmlFor="verificationCode">Enter Verification Code:</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="verificationCode"
//                       value={verificationCode}
//                       onChange={(e) => setVerificationCode(e.target.value)}
//                       placeholder="Enter verification code"
//                       required
//                     />
//                     <button
//                       type="button"
//                       className="btn btn-success btn-block mt-3"
//                       onClick={handleVerifyCode}
//                     >
//                       Verify Code
//                     </button>
//                   </div>
//                 )}
//                 <div id="message" className="text-center">{message}</div>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// }

// export default MobileOTP;




import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier, signInWithPhoneNumber, auth } from './Firebase'; // Adjust Firebase imports as per your setup
import { useParams } from 'react-router-dom';
import './Mobile.css';
import CryptoJS from 'crypto-js';
import secretKey from './components/Secret.js';



function MobileOTP() {
  const [message, setMessage] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showVerificationCodeInput, setShowVerificationCodeInput] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [lastResendTime, setLastResendTime] = useState();
  const [confirmationResult, setConfirmationResult] = useState();
  // const { number } = useParams(); // Assuming `Mobile` contains the phone number from useParams
  
  
  const {numberToken } = useParams();


  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(numberToken) , secretKey);
  

  
  const number = bytes.toString(CryptoJS.enc.Utf8);



  let recaptchaVerifier;

  const setUpRecaptcha = () => {
    if (!recaptchaVerifier) {
      recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'normal',
        callback: () => {
          setShowVerificationCodeInput(true); // Show verification code input when reCAPTCHA is verified
          console.log('reCAPTCHA solved');
        },
        'expired-callback': () => {
          console.log('reCAPTCHA expired');
        }
      });
    }
  };

  const handleSignInWithPhone = (phoneNumber) => {
    setUpRecaptcha();
    const appVerifier = recaptchaVerifier;

    signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
      .then((result) => {
        setResendCount(prevCount => prevCount + 1);
        setConfirmationResult(result);
        setLastResendTime(Date.now());
        setShowVerificationCodeInput(true); // Show input for verification code after sending OTP
        setMessage('Verification code sent.');
      })
      .catch((error) => {
        console.error('Error during phone login request:', error);
        setMessage(error.message);
      });
  };

  const handleVerifyCode = () => {

    // Handle code verification here

    console.log('Verifying code:', verificationCode);
        if (confirmationResult) {
          confirmationResult.confirm(verificationCode)
            .then((result) => {
              console.log('User signed in successfully:', result.user);
              setMessage('User signed in successfully.');
              // Redirect or perform further actions upon successful verification
              window.location.pathname = '/home';
            })
            .catch((error) => {
              console.error('Error verifying code:', error);
              setMessage(error.message);
            });
        } else {
          setMessage('No confirmation result available. Please resend the verification code.');
        }







  };

  const handleResendCode = () => {
    if (resendCount >= 3) {
      setMessage('You have reached the maximum limit of resend attempts. Please try again later.');
      return;
    }

    const hoursElapsed = lastResendTime ? (Date.now() - lastResendTime) / (1000 * 60 * 60) : null;

    if (hoursElapsed && hoursElapsed < 12) {
      setMessage('You can only resend the code after 12 hours.');
      return;
    }

    handleSignInWithPhone(number);
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title text-center mb-4">Verify Phone Number</h3>
              <form>
                {!showVerificationCodeInput && (
                  <div className="form-group" id="recaptcha-container"></div>
                )}
                {!showVerificationCodeInput ? (
                  <button
                    type="button"
                    className="btn btn-primary btn-block mb-3"
                    onClick={() => handleSignInWithPhone(number)}
                  >
                    Send Verification Code
                  </button>
                ) : (
                  <div className="form-group">
                    <label htmlFor="verificationCode">Enter Verification Code:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="verificationCode"
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      placeholder="Enter verification code"
                      required
                    />
                    <button
                      type="button"
                      className="btn btn-success btn-block mt-3"
                      onClick={handleVerifyCode}
                    >
                      Verify Code
                    </button>
                    <button
                      type="button"
                      className="btn btn-success btn-block mt-3"
                      onClick={handleResendCode}
                    >
                      Resend Code
                    </button>
                    {resendCount >= 3 && (
                      <div className="alert alert-danger mt-3">
                        You have reached the maximum limit of resend attempts. Please try again later.
                      </div>
                    )}
                  </div>
                )}
                <div id="message" className="text-center">{message}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileOTP;
