// My perfect login logic with Email and password , login with google , login with linkedin , Login with mobile OTP

// import React, { useState } from 'react';
// import './App.css';
// import { Link } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import SERVER_URL from './components/URL';
// import Axios from 'axios';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import Forgot from './Forgot';
// import { auth, RecaptchaVerifier, signInWithPhoneNumber , myProvider } from './Firebase.js';
// import { signInWithPopup} from 'firebase/auth'





// const Login = () => {
//   const [message, setMessage] = useState('');
//   const [appear, setAppear] = useState(false);
//   const [loginWithPhone, setLoginWithPhone] = useState(false);
//   const [forgot, setForgot] = useState(false); // State to control modal visibility
  // const [confirmationResult, setConfirmationResult] = useState(null);
  // const [verificationCode, setVerificationCode] = useState('');
//   const [Defaultcountry , setDefaultCountry] = useState('')


//   let recaptchaVerifier;


//   const linkedInClientId = '86twjp6r64jxz2';
//   const linkedInRedirectUri = 'http://localhost:3000/callback';
  
// const linkedInScope = 'r_liteprofile r_emailaddress'





//   const formStructure = Yup.object({
//     username: Yup.string()
//       .required('Username is required')
//       .test('username', 'Invalid username', function (value) {
//         if (/^\d+$/.test(value)) {
//           return value.length >= 5 && value.length <= 15;
//         } else {
//           return Yup.string().email().isValidSync(value);
//         }
//       }),
//     password: Yup.string().required('Password is required'),
//   });

//   const phoneLoginStructure = Yup.object({
//     phoneNumber: Yup.string()
//       .required('Phone number is required')
//       .matches(/^\d+$/, 'Phone number must be numeric')
//       .min(5, 'Phone number must be at least 5 digits')
//       .max(15, 'Phone number must be at most 15 digits'),
//   });



//   React.useEffect(() => {
//     const fetchGeoIP = async () => {
//       try {
//         const response = await Axios.get('https://ipinfo.io');
//         if (response && response.data && response.data.country) {
//           // Extract country code from the response
//           const countryCode = response.data.country.toLowerCase();
//           setDefaultCountry(countryCode);
//         } else {
//           console.error('Failed to fetch GeoIP data:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching GeoIP data:', error);
//       }
//     };

//     fetchGeoIP();
//   }, []);







  // function toggleConfirmPasswordVisibility() {
  //   setAppear(!appear);
  // }


 



// function handleLinkedInSignIn()
// {
//   const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=${encodeURIComponent(linkedInScope)}`;
//   window.location.href = linkedInAuthUrl;
// }












//   function Google()
//   {
    
//     signInWithPopup(auth, myProvider)
//     .then(function()
//     {
//         // Logic to extract Username and Email ID
//         let username = auth.currentUser.displayName
//         let email = auth.currentUser.email

//         console.log(username, email)
        

//         // Navigate to Home Page(/home)
        
//         // useNavigate("/home")

       
//        window.location.pathname = '/home'
       
       
        
//     })
//     .catch(function(error)
//     {
//         console.log(error)
//     })

//   }

  

  // const setUpRecaptcha = () => {
  //   if (!recaptchaVerifier) {

      
  //     recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
  //       'size': 'normal',
  //       'callback': (response) => {
  //         console.log(response)
  //         console.log('reCAPTCHA solved');
  //       },
  //       'expired-callback': () => {
  //         console.log('reCAPTCHA expired');
  //       }
  //     });

     
  //   }

    


  //   console.log('reCAPTCHA verifier initialized:', recaptchaVerifier);
  // };






  // const handleSignInWithPhone = (phoneNumber) => {
  //   console.log('Handling sign in with phone:', phoneNumber);
  //   setUpRecaptcha();

    
  //   const appVerifier = recaptchaVerifier;

  //   console.log(appVerifier)

  //   signInWithPhoneNumber(auth, `+${phoneNumber}`, appVerifier)
  //     .then((confirmationResult) => {
  //       setConfirmationResult(confirmationResult);
  //       console.log('SMS sent, confirmation result:', confirmationResult);
  //       setMessage('Verification code sent.');
  //     }).catch((error) => {
  //       console.error('Error during phone login request:', error);
  //       setMessage(error.message);
  //     });
  // };

  // const verifyCode = () => {
  //   console.log('Verifying code:', verificationCode);
  //   if (confirmationResult) {
  //     confirmationResult.confirm(verificationCode)
  //       .then((result) => {
  //         console.log(result)
  //         const user = result.user;
  //         console.log('User signed in successfully:', user);
  //         setMessage('User signed in successfully.');
          
  //         window.location.pathname = '/home'

          


  //         // Proceed with additional authentication if necessary
  //       }).catch((error) => {
  //         console.error('Error verifying code:', error);
  //         setMessage(error.message);
  //       });
  //   } else {
  //     setMessage('No confirmation result available. Please resend the verification code.');
  //   }
  // };

//   return (
//     <div className="login-container">
//       <div className="login-box">
        

//         <button onClick={handleLinkedInSignIn} className="linkedin-button">
//           <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//             <g id="LinkedIn">
//               <path fill="#FFFFFF" d="M12 12H4V36H12zM8 4a4 4 0 100 8 4 4 0 000-8zM36 12h-8v4.78c0 .15 0 .3-.03.45-.24 1.11-.96 2.05-2.08 2.66V12h-8v24h8v-11.7c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5V36h8V20.5c0-4.56-3.94-8.5-8.5-8.5z"/>
//             </g>
//           </svg>
//           Sign in with LinkedIn
//         </button>


//         <button onClick={Google}  style={{marginBottom:'10px'}} type="button"  className="google-button" >


//         <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//         <g id="Google">
//           <path fill="#4285F4" d="M45.38,20.86c0,-1.25 -0.11,-2.47 -0.31,-3.67H24v6.97h12.14c-0.52,2.78 -2.62,5.11 -5.55,6.23v5.16h8.92c5.23,-4.82 8.23,-11.95 8.23,-20.69z"/>
//           <path fill="#34A853" d="M24,48c7.47,0 13.71,-2.47 18.26,-6.7l-8.92,-6.94c-2.48,1.69 -5.64,2.68 -9.34,2.68 -7.17,0 -13.22,-4.86 -15.37,-11.41H0.94v5.66C5.49,42.24 14.25,48 24,48z"/>
//           <path fill="#FBBC05" d="M8.63,28.74c-0.5,-1.5 -0.75,-3.11 -0.75,-4.74s0.25,-3.24 0.75,-4.74V13.67H0.94C0.34,15.95 0,18.36 0,20.86s0.34,4.91 0.94,7.19L8.63,28.74z"/>
//           <path fill="#EA4335" d="M24,9.24c3.22,0 6.14,1.1 8.46,2.92l6.33,-6.33C37.71,2.47 31.47,0 24,0 14.25,0 5.49,5.76 0.94,13.67l8.69,6.75c2.14,-6.55 8.2,-11.41 15.37,-11.41z"/>
//           <path fill="none" d="M0,0h48v48h-48z"/>
//         </g>
//       </svg>
//       Sign in with Google




//         </button>

        
//         {!loginWithPhone ? (
//           <Formik
//             initialValues={{ username: '', password: '' }}
//             validationSchema={formStructure}
//             onSubmit={(values, { setSubmitting }) => {
//               console.log('Submitting login form with values:', values);
//               Axios.post(`${SERVER_URL}/login`, values)
//                 .then(function (output) {
//                   console.log('Server response:', output);
//                   const { message, redirectUrl } = output.data;
//                   setMessage(message);
                 
//                   if (redirectUrl) window.location.href = redirectUrl;

                
//                 })
//                 .catch(function (error) {
//                   console.error('Error during login request:', error);
//                   setMessage(error.response?.data?.message || 'An error occurred');
//                   setSubmitting(false);
//                 });
//             }}
//           >
//             {({ isSubmitting }) => (
//               <Form>
//                 <div className="input-group">
//                   <Field
//                     type="text"
//                     name="username"
//                     placeholder="Enter email "
//                     id='login-username'
//                   />
//                   <ErrorMessage name="username" component="div" className="error" />
//                 </div>
//                 <div className="input-group">
//                   <div className='together'>
//                     <Field
//                       type={appear ? 'text' : 'password'}
//                       name="password"
//                       placeholder="Password"
//                       id='login-password'
//                     />
//                     <span onClick={toggleConfirmPasswordVisibility}>
//                       <i id='Eye' style={{ color: appear ? 'red' : 'gray' }} className="fa fa-eye"></i>
//                     </span>
//                   </div>
//                   <ErrorMessage name="password" component="div" className="error" />
//                 </div>
//                 <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Login</button>
//                 <div id='message'>{message ? message : null}</div>
//               </Form>
//             )}
//           </Formik>
//         ) : (
//           <div>
//             {!confirmationResult ? (
//               <Formik
//                 initialValues={{ phoneNumber: '' }}
//                 validationSchema={phoneLoginStructure}
//                 onSubmit={(values, { setSubmitting }) => {
//                   console.log('Submitting phone login form with values:', values);
//                   handleSignInWithPhone(values.phoneNumber);
//                   setSubmitting(false); // Ensure to reset the submitting state
//                 }}
//               >
//                 {({ setFieldValue, isSubmitting }) => (
//                   <Form>
//                     <div className="input-group">
//                       <Field name="phoneNumber">
//                         {({ field }) => (
//                           <PhoneInput
//                             id='phonelogin'
//                             country={Defaultcountry}
//                             value={field.value}
//                             onChange={(phone) => setFieldValue('phoneNumber', phone)}
//                             inputProps={{
//                               name: 'phoneNumber',
//                               required: true,
//                               autoFocus: true
//                             }}
//                           />
//                         )}
//                       </Field>
//                       <ErrorMessage name="phoneNumber" component="div" className="error" />
//                     </div>
//                     <div id="recaptcha-container"></div>
//                     <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Send Verification Code</button>
//                     <div id='message'>{message ? message : null}</div>
//                   </Form>
//                 )}
//               </Formik>
//             ) : (
//               <div>
//                 <input
//                   type="text"
//                   value={verificationCode}
//                   onChange={(e) => setVerificationCode(e.target.value)}
//                   placeholder="Enter verification code"
//                 />
//                 <button onClick={verifyCode}>Verify Code</button>
//                 <div id='message'>{message ? message : null}</div>
//               </div>
//             )}
//           </div>
//         )}

//         <Link className="login-links" onClick={() => setLoginWithPhone(!loginWithPhone)}>
//           {loginWithPhone ? 'Login with Email' : 'Login with Phone'}
//         </Link>

//         <div className="login-links">
//           <Link to='/forgot' >Forgot Password?</Link>
         
          
//           <span>or</span>
//           <Link to='/register'>Create an Account</Link>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;

























// import React, { useState, useEffect } from 'react';
// import './App.css';
// import { Link, Outlet, useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Axios from 'axios';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import { auth, myProvider } from './Firebase.js';
// import { signInWithPopup} from 'firebase/auth'
// import { FaEnvelope } from 'react-icons/fa';
// import axios from 'axios';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import { useContext } from 'react'
// import { MyBox } from './App'

// function Login(props) {
//   const [defaultCountry, setDefaultCountry] = useState(''); // Default country state
//   const [isPhoneInput, setIsPhoneInput] = useState(false);
//   const [isEmailInput, setIsEmailInput] = useState(false);
//   const [isValid, setIsValid] = useState(false);

//   let username = ''

//   const [details , setdetails]  = useState({

//       emailOrPhone : ''
//   })
//   const {loginbox} = useContext(MyBox)


//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchGeoIP = async () => {
//       try {
//         const response = await axios.get('https://ipinfo.io');
//         if (response && response.data && response.data.country) {
//           const country = response.data.country.toLowerCase();
//           setDefaultCountry(country); // Set default country based on IP
//         } else {
//           console.error('Failed to fetch GeoIP data:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching GeoIP data:', error);
//       }
//     };

//     fetchGeoIP(); // Initial fetch of GeoIP data on component mount
//   }, []);

//   const validationSchema = Yup.object().shape({
//     emailOrPhone: Yup.string()
//       .test(
//         'emailOrPhone',
//         'Invalid email or phone number',
//         value => {
//           const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//           const phoneRegex = /^\d{5,15}$/;
  
//           // Check if it's a valid email
//           if (emailRegex.test(value)) {
//             return true;
//           }
  
//           // Check if it's a valid phone number for the selected country
//           const phoneNumber = parsePhoneNumberFromString(value, defaultCountry.toUpperCase());
//           if (phoneNumber) {
//             return phoneRegex.test(value) && phoneNumber.isValid();
//           }
  
//           return false;
//         }
//       )
//       .required('Required'),
//   });


//   const linkedInClientId = '86twjp6r64jxz2';
//   const linkedInRedirectUri = 'http://localhost:3000/callback';
  
// const linkedInScope = 'r_liteprofile r_emailaddress'

// function handleLinkedInSignIn()
// {
//   const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=${encodeURIComponent(linkedInScope)}`;
//   window.location.href = linkedInAuthUrl;
// }



//   function Google()
//   {
    
//     signInWithPopup(auth, myProvider)
//     .then(function()
//     {
//         // Logic to extract Username and Email ID
//         let username = auth.currentUser.displayName
//         let email = auth.currentUser.email

//         console.log(username, email)
        

//         // Navigate to Home Page(/home)
        
//         // useNavigate("/home")

       
//        window.location.pathname = '/home'
       
       
        
//     })
//     .catch(function(error)
//     {
//         console.log(error)
//     })

//   }



// username = details

// console.log(details)





//   return (
    
     

//       <div className="login-container">
        
// {loginbox ? (<div>



//   <button onClick={handleLinkedInSignIn} className="linkedin-button">
//             <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//               <g id="LinkedIn">
//                 <path fill="#FFFFFF" d="M12 12H4V36H12zM8 4a4 4 0 100 8 4 4 0 000-8zM36 12h-8v4.78c0 .15 0 .3-.03.45-.24 1.11-.96 2.05-2.08 2.66V12h-8v24h8v-11.7c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5V36h8V20.5c0-4.56-3.94-8.5-8.5-8.5z" />
//               </g>
//             </svg>
//             Sign in with LinkedIn
//           </button>
  
//           <button onClick={Google} style={{ marginBottom: '10px' }} type="button" className="google-button">
//             <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//               <g id="Google">
//                 <path fill="#4285F4" d="M45.38,20.86c0,-1.25 -0.11,-2.47 -0.31,-3.67H24v6.97h12.14c-0.52,2.78 -2.62,5.11 -5.55,6.23v5.16h8.92c5.23,-4.82 8.23,-11.95 8.23,-20.69z" />
//                 <path fill="#34A853" d="M24,48c7.47,0 13.71,-2.47 18.26,-6.7l-8.92,-6.94c-2.48,1.69 -5.64,2.68 -9.34,2.68 -7.17,0 -13.22,-4.86 -15.37,-11.41H0.94v5.66C5.49,42.24 14.25,48 24,48z" />
//                 <path fill="#FBBC05" d="M8.63,28.74c-0.5,-1.5 -0.75,-3.11 -0.75,-4.74s0.25,-3.24 0.75,-4.74V13.67H0.94C0.34,15.95 0,18.36 0,20.86s0.34,4.91 0.94,7.19L8.63,28.74z" />
//                 <path fill="#EA4335" d="M24,9.24c3.22,0 6.14,1.1 8.46,2.92l6.33,-6.33C37.71,2.47 31.47,0 24,0 14.25,0 5.49,5.76 0.94,13.67l8.69,6.75c2.14,-6.55 8.2,-11.41 15.37,-11.41z" />
//                 <path fill="none" d="M0,0h48v48h-48z" />
//               </g>
//             </svg>
//             Sign in with Google
//           </button>
//           <Formik
//     initialValues={{
//       emailOrPhone: '',
//     }}
//     validationSchema={validationSchema}
//     onSubmit={(values) => {
      
// // const containsAlphabets = /[a-zA-Z]/.test(values.emailOrPhone);
// //               if (containsAlphabets) {
// //                 navigate('/login/email');
// //               } else {
// //                 navigate('/login/mobile');
// //               }


//     }}
//     validate={(values) => {
//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       const phoneRegex = /^\d{5,15}$/;
//       const phoneNumber = parsePhoneNumberFromString(values.emailOrPhone, defaultCountry.toUpperCase());
  
//       // Check if either email or phone number is valid
//       const isValid = emailRegex.test(values.emailOrPhone) || (phoneNumber && phoneNumber.isValid() && phoneRegex.test(values.emailOrPhone));
//       setIsValid(isValid);
//     }}
//   >
//   {({ setFieldValue }) => (
//       <Form method='post' >
//         <div className="input-group">
//           <Field name="emailOrPhone">
//             {({ field }) => (
//               isPhoneInput ? (
//                 <div className="phone-input">
//                   <PhoneInput
//                     id='phonelogin'
//                     country={defaultCountry || 'auto'}
//                     value={field.value}
//                     onChange={(phone) => {
//                       setFieldValue('emailOrPhone', phone);
//                       setIsPhoneInput(/^\d/.test(phone));
//                       setdetails(phone)
//                     }}
//                     inputProps={{
//                       name: 'emailOrPhone',
//                       required: true,
//                       autoFocus: true,
//                     }}
//                     dropdownClass="custom-dropdown"
//                     placeholder="Enter phone number"
//                     enableSearch
//                   />
//                 </div>
//               ) : (
//                 <div className="email-wrapper">
//                   <input
//                     type="text"
//                     className="input-box"
//                     placeholder="Email"
//                     {...field}
//                     name='emailOrPhone'
//                     onChange={(e) => {
//                       setFieldValue('emailOrPhone', e.target.value);
//                       setIsPhoneInput(/^\d/.test(e.target.value));
//                       const containsAlphabets = /[a-zA-Z]/.test(e.target.value);
//                       setdetails(e.target.value)
//                       setIsEmailInput(containsAlphabets);
//                     }}
//                     required
//                   />
//                   {isEmailInput && (
//                     <FaEnvelope className="email-icon" />
//                   )}
//                 </div>
//               )
//             )}
//           </Field>
//           <ErrorMessage name="emailOrPhone" component="div" className="error-message" />
//         </div>
  
//         {/* Enable the Next button conditionally based on isValid */}
//         {isValid ? (
//           <Link to={`/login/${username}`}   >
          
//           <button type="submit" className="btn">
//             Next
//           </button>
          
//           </Link>
//         ) : (
//           <button type="submit" className="btn" disabled>
//             Next
//           </button>
//         )}
//       </Form>
//     )}
//   </Formik>
//           <div className="options">
//           <label>
//             <input type="checkbox" /> Stay signed in
//           </label>
//           <a href="#">Forgot username?</a>
//         </div>
//         <Link to='/register' ><button className="btn">Create an account</button></Link>


// </div>)   : null}


//         <Outlet />


//         </div>
        
        



     
    
//   );
// }

// export default Login;
















// import React, { useState, useEffect } from 'react';
// import './App.css';
// import { Link, Outlet, useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Axios from 'axios';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import { auth, myProvider } from './Firebase.js';
// import { signInWithPopup} from 'firebase/auth'
// import { FaEnvelope } from 'react-icons/fa';
// import axios from 'axios';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import { createContext } from 'react';
// import { useContext } from 'react'
// import { MyBox } from './App'
// import EmailLogin from './Emaillogin.js';
// import MobileOTP from './MobileOTP.js';



// export const LoginForm = createContext()

// function Login(props) {
//   const [defaultCountry, setDefaultCountry] = useState(''); // Default country state
//   const [isPhoneInput, setIsPhoneInput] = useState(false);
//   const [isEmailInput, setIsEmailInput] = useState(false);
//   const [isValid, setIsValid] = useState(false);

//   const[loginbox , setLoginbox] = useState(true)


  

//   let username = ''

//   const [details , setdetails]  = useState({

//       emailOrPhone : ''
//   })
//   // const {loginbox} = useContext(MyBox)

  

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchGeoIP = async () => {
//       try {
//         const response = await axios.get('https://ipinfo.io');
//         if (response && response.data && response.data.country) {
//           const country = response.data.country.toLowerCase();
//           setDefaultCountry(country); // Set default country based on IP
//         } else {
//           console.error('Failed to fetch GeoIP data:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching GeoIP data:', error);
//       }
//     };

//     fetchGeoIP(); // Initial fetch of GeoIP data on component mount
//   }, []);

//   const validationSchema = Yup.object().shape({
//     emailOrPhone: Yup.string()
//       .test(
//         'emailOrPhone',
//         'Invalid email or phone number',
//         value => {
//           const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//           const phoneRegex = /^\d{5,15}$/;
  
//           // Check if it's a valid email
//           if (emailRegex.test(value)) {
//             return true;
//           }
  
//           // Check if it's a valid phone number for the selected country
//           const phoneNumber = parsePhoneNumberFromString(value, defaultCountry.toUpperCase());
//           if (phoneNumber) {
//             return phoneRegex.test(value) && phoneNumber.isValid();
//           }
  
//           return false;
//         }
//       )
//       .required('Required'),
//   });


//   const linkedInClientId = '86twjp6r64jxz2';
//   const linkedInRedirectUri = 'http://localhost:3000/callback';
  
// const linkedInScope = 'r_liteprofile r_emailaddress'

// function handleLinkedInSignIn()
// {
//   const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=${encodeURIComponent(linkedInScope)}`;
//   window.location.href = linkedInAuthUrl;
// }



//   function Google()
//   {
    
//     signInWithPopup(auth, myProvider)
//     .then(function()
//     {
//         // Logic to extract Username and Email ID
//         let username = auth.currentUser.displayName
//         let email = auth.currentUser.email

//         console.log(username, email)
        

//         // Navigate to Home Page(/home)
        
//         // useNavigate("/home")

       
//        window.location.pathname = '/home'
       
       
        
//     })
//     .catch(function(error)
//     {
//         console.log(error)
//     })

//   }



// username = details

// // console.log(details)






//   return (
    
//      <div>


// <LoginForm.Provider   value={{Logindata : setLoginbox}} >





// {loginbox ? 

// <div className="login-container">
  




// <button onClick={handleLinkedInSignIn} className="linkedin-button">
//       <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//         <g id="LinkedIn">
//           <path fill="#FFFFFF" d="M12 12H4V36H12zM8 4a4 4 0 100 8 4 4 0 000-8zM36 12h-8v4.78c0 .15 0 .3-.03.45-.24 1.11-.96 2.05-2.08 2.66V12h-8v24h8v-11.7c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5V36h8V20.5c0-4.56-3.94-8.5-8.5-8.5z" />
//         </g>
//       </svg>
//       Sign in with LinkedIn
//     </button>

//     <button onClick={Google} style={{ marginBottom: '10px' }} type="button" className="google-button">
//       <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//         <g id="Google">
//           <path fill="#4285F4" d="M45.38,20.86c0,-1.25 -0.11,-2.47 -0.31,-3.67H24v6.97h12.14c-0.52,2.78 -2.62,5.11 -5.55,6.23v5.16h8.92c5.23,-4.82 8.23,-11.95 8.23,-20.69z" />
//           <path fill="#34A853" d="M24,48c7.47,0 13.71,-2.47 18.26,-6.7l-8.92,-6.94c-2.48,1.69 -5.64,2.68 -9.34,2.68 -7.17,0 -13.22,-4.86 -15.37,-11.41H0.94v5.66C5.49,42.24 14.25,48 24,48z" />
//           <path fill="#FBBC05" d="M8.63,28.74c-0.5,-1.5 -0.75,-3.11 -0.75,-4.74s0.25,-3.24 0.75,-4.74V13.67H0.94C0.34,15.95 0,18.36 0,20.86s0.34,4.91 0.94,7.19L8.63,28.74z" />
//           <path fill="#EA4335" d="M24,9.24c3.22,0 6.14,1.1 8.46,2.92l6.33,-6.33C37.71,2.47 31.47,0 24,0 14.25,0 5.49,5.76 0.94,13.67l8.69,6.75c2.14,-6.55 8.2,-11.41 15.37,-11.41z" />
//           <path fill="none" d="M0,0h48v48h-48z" />
//         </g>
//       </svg>
//       Sign in with Google
//     </button>
//     <Formik
// initialValues={{
// emailOrPhone: '',
// }}
// validationSchema={validationSchema}
// onSubmit={(values) => {

// const containsAlphabets = /[a-zA-Z]/.test(values.emailOrPhone);
// console.log(containsAlphabets)
//         if (containsAlphabets) {
          


//           // setLoginbox(false)
          
//           navigate(`Email/${username}`);

//           // setLoginbox(false)
          
//           // window.location.pathname = `/login/Email/${username}`

//           // setLoginbox(false)
//         }
//         else {

         

//           navigate(`OTP/${username}`);

//           // setLoginbox(false)

//           // setLoginbox(false)
//         }


// }}
// validate={(values) => {
// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
// const phoneRegex = /^\d{5,15}$/;
// const phoneNumber = parsePhoneNumberFromString(values.emailOrPhone, defaultCountry.toUpperCase());

// // Check if either email or phone number is valid
// const isValid = emailRegex.test(values.emailOrPhone) || (phoneNumber && phoneNumber.isValid() && phoneRegex.test(values.emailOrPhone));
// setIsValid(isValid);
// }}
// >
// {({ setFieldValue }) => (
// <Form method='post' >
//   <div className="input-group">
//     <Field name="emailOrPhone">
//       {({ field }) => (
//         isPhoneInput ? (
//           <div className="phone-input">
//             <PhoneInput
//               id='phonelogin'
//               country={defaultCountry || 'auto'}
//               value={field.value}
//               onChange={(phone) => {
//                 setFieldValue('emailOrPhone', phone);
//                 setIsPhoneInput(/^\d/.test(phone));
//                 setdetails(phone)
//               }}
//               inputProps={{
//                 name: 'emailOrPhone',
//                 required: true,
//                 autoFocus: true,
//               }}
//               dropdownClass="custom-dropdown"
//               placeholder="Enter phone number"
//               enableSearch
//             />
//           </div>
//         ) : (
//           <div className="email-wrapper">
//             <input
//               type="text"
//               className="input-box"
//               placeholder="Email"
//               {...field}
//               name='emailOrPhone'
//               onChange={(e) => {
//                 setFieldValue('emailOrPhone', e.target.value);
//                 setIsPhoneInput(/^\d/.test(e.target.value));
//                 const containsAlphabets = /[a-zA-Z]/.test(e.target.value);
//                 setdetails(e.target.value)
//                 setIsEmailInput(containsAlphabets);
//               }}
//               required
//             />
//             {isEmailInput && (
//               <FaEnvelope className="email-icon" />
//             )}
//           </div>
//         )
//       )}
//     </Field>
//     <ErrorMessage name="emailOrPhone" component="div" className="error-message" />
//   </div>

//   {/* Enable the Next button conditionally based on isValid */}
//   {isValid ? (
    
    
//     <button type="submit" className="btn">
//       Next
//     </button>
    
    
//   ) : (
//     <button type="submit" className="btn" disabled>
//       Next
//     </button>
//   )}
// </Form>
// )}
// </Formik>
//     <div className="options">
//     <label>
//       <input type="checkbox" /> Stay signed in
//     </label>
//     <a href="#">Forgot username?</a>
//   </div>
//   <Link to='/register' ><button className="btn">Create an account</button></Link>





//   <Outlet />


//   </div>
  
  

// :

// window.location.pathname.includes('/Email') ? <EmailLogin   /> : <MobileOTP/>



// }







// </LoginForm.Provider>






//      </div>

     
    
//   );
// }

// export default Login;













// import React, { useState, useEffect, createContext, useContext } from 'react';
// import './App.css';
// import { Link, Outlet, useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import Axios from 'axios';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import { auth, myProvider } from './Firebase.js';
// import { signInWithPopup ,  signOut } from 'firebase/auth';
// import { FaEnvelope } from 'react-icons/fa';
// import axios from 'axios';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import EmailLogin from './Emaillogin.js';
// import MobileOTP from './MobileOTP.js';

// export const LoginForm = createContext();

// function Login() {
//   const [defaultCountry, setDefaultCountry] = useState(''); // Default country state
//   const [isPhoneInput, setIsPhoneInput] = useState(false);
//   const [isEmailInput, setIsEmailInput] = useState(false);
//   const [isValid, setIsValid] = useState(false);
//   // const [loginbox, setLoginbox] = useState(true);



//   const [Loginbox, setLoginbox] = useState(false)


//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchGeoIP = async () => {
//       try {
//         const response = await Axios.get('https://ipinfo.io');
//         if (response && response.data && response.data.country) {
//           const country = response.data.country.toLowerCase();
//           setDefaultCountry(country); // Set default country based on IP
//         } else {
//           console.error('Failed to fetch GeoIP data:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching GeoIP data:', error);
//       }
//     };
//     fetchGeoIP(); // Initial fetch of GeoIP data on component mount
//   },[]);

//   const validationSchema = Yup.object().shape({
//     emailOrPhone: Yup.string()
//       .test(
//         'emailOrPhone',
//         'Invalid email or phone number',
//         value => {
//           const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//           const phoneRegex = /^\d{5,15}$/;

//           // Check if it's a valid email
//           if (emailRegex.test(value)) {
//             return true;
//           }

//           // Check if it's a valid phone number for the selected country
//           const phoneNumber = parsePhoneNumberFromString(value, defaultCountry.toUpperCase());
//           if (phoneNumber) {
//             return phoneRegex.test(value) && phoneNumber.isValid();
//           }

//           return false;
//         }
//       )
//       .required('Required'),
//   });

//   const handleLinkedInSignIn = () => {
//     const linkedInClientId = '86twjp6r64jxz2';
//     const linkedInRedirectUri = 'http://localhost:3000/callback';
//     const linkedInScope = 'r_liteprofile r_emailaddress';
//     const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=${encodeURIComponent(linkedInScope)}`;
//     window.location.href = linkedInAuthUrl;
//   };

//   const Google = () => {
//     signInWithPopup(auth, myProvider)
//       .then(() => {
//         let username = auth.currentUser.displayName;
//         let email = auth.currentUser.email;
//         console.log(username, email);
//         navigate('/home');
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   };

//   return (
    
     
        
//           <div className="login-container">
            


//   <button onClick={handleLinkedInSignIn} className="linkedin-button">
//               <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//                 <g id="LinkedIn">
//                   <path fill="#FFFFFF" d="M12 12H4V36H12zM8 4a4 4 0 100 8 4 4 0 000-8zM36 12h-8v4.78c0 .15 0 .3-.03.45-.24 1.11-.96 2.05-2.08 2.66V12h-8v24h8v-11.7c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5V36h8V20.5c0-4.56-3.94-8.5-8.5-8.5z" />
//                 </g>
//               </svg>
//               Sign in with LinkedIn
//             </button>

//             <button onClick={Google} style={{ marginBottom: '10px' }} type="button" className="google-button">
//               <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
//                 <g id="Google">
//                   <path fill="#4285F4" d="M45.38,20.86c0,-1.25 -0.11,-2.47 -0.31,-3.67H24v6.97h12.14c-0.52,2.78 -2.62,5.11 -5.55,6.23v5.16h8.92c5.23,-4.82 8.23,-11.95 8.23,-20.69z" />
//                   <path fill="#34A853" d="M24,48c7.47,0 13.71,-2.47 18.26,-6.7l-8.92,-6.94c-2.48,1.69 -5.64,2.68 -9.34,2.68 -7.17,0 -13.22,-4.86 -15.37,-11.41H0.94v5.66C5.49,42.24 14.25,48 24,48z" />
//                   <path fill="#FBBC05" d="M8.63,28.74c-0.5,-1.5 -0.75,-3.11 -0.75,-4.74s0.25,-3.24 0.75,-4.74V13.67H0.94C0.34,15.95 0,18.36 0,20.86s0.34,4.91 0.94,7.19L8.63,28.74z" />
//                   <path fill="#EA4335" d="M24,9.24c3.22,0 6.14,1.1 8.46,2.92l6.33,-6.33C37.71,2.47 31.47,0 24,0 14.25,0 5.49,5.76 0.94,13.67l8.69,6.75c2.14,-6.55 8.2,-11.41 15.37,-11.41z" />
//                   <path fill="none" d="M0,0h48v48h-48z" />
//                 </g>
//               </svg>
//               Sign in with Google
//             </button>

//             <Formik
//               initialValues={{ emailOrPhone: '' }}
//               validationSchema={validationSchema}
//               onSubmit={(values) => {
                
//                 const containsAlphabets = /[a-zA-Z]/.test(values.emailOrPhone);
//                 if (containsAlphabets) {
                  

//                  window.location.pathname = `/login/Email/${values.emailOrPhone}`
                  
//                   // navigate(`Email/${values.emailOrPhone}`);
//                 } else {
//                   // navigate(`OTP/${values.emailOrPhone}`);

//                    window.location.pathname = `/login/OTP/${values.emailOrPhone}`
//                 }
//               }}
//               validate={(values) => {
//                 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//                 const phoneRegex = /^\d{5,15}$/;
//                 const phoneNumber = parsePhoneNumberFromString(values.emailOrPhone, defaultCountry.toUpperCase());
//                 const isValid = emailRegex.test(values.emailOrPhone) || (phoneNumber && phoneNumber.isValid() && phoneRegex.test(values.emailOrPhone));
//                 setIsValid(isValid);
//               }}
//             >
//               {({ setFieldValue }) => (
//                 <Form method='post'>
//                   <div className="input-group">
//                     <Field name="emailOrPhone">
//                       {({ field }) => (
//                         isPhoneInput ? (
//                           <div className="phone-input">
//                             <PhoneInput
//                               id='phonelogin'
//                               country={defaultCountry || 'auto'}
//                               value={field.value}
//                               onChange={(phone) => {
//                                 setFieldValue('emailOrPhone', phone);
//                                 setIsPhoneInput(/^\d/.test(phone));
//                               }}
//                               inputProps={{
//                                 name: 'emailOrPhone',
//                                 required: true,
//                                 autoFocus: true,
//                               }}
//                               dropdownClass="custom-dropdown"
//                               placeholder="Enter phone number"
//                               enableSearch
//                             />
//                           </div>
//                         ) : (
//                           <div className="email-wrapper">
//                             <input
//                               type="text"
//                               className="input-box"
//                               placeholder="Email"
//                               {...field}
//                               name='emailOrPhone'
//                               onChange={(e) => {
//                                 setFieldValue('emailOrPhone', e.target.value);
//                                 setIsPhoneInput(/^\d/.test(e.target.value));
//                                 const containsAlphabets = /[a-zA-Z]/.test(e.target.value);
//                                 setIsEmailInput(containsAlphabets);
//                               }}
//                               required
//                             />
//                             {isEmailInput && <FaEnvelope className="email-icon" />}
//                           </div>
//                         )
//                       )}
//                     </Field>
//                     <ErrorMessage name="emailOrPhone" component="div" className="error-message" id='Login-error' />
//                   </div>
//                   {isValid ? (
//                     <button type="submit" className="btn">Next</button>
//                   ) : (
//                     <button type="submit" className="btn" disabled>Next</button>
//                   )}
//                 </Form>
//               )}
//             </Formik>
// {/* 
//             <div className="options">
//               <label>
//                 <input type="checkbox" /> Stay signed in
//               </label>
//               <a href="#">Forgot username?</a>
//             </div> */}

//             <Link style={{textDecoration:'none'}} to='/register'><button className="btn">Create an account</button></Link>




//           </div>
        


    
    
//   );
// }

// export default Login;






import React, { useState, useEffect } from 'react';
import './App.css';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FaEnvelope } from 'react-icons/fa';
import Axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { auth, myProvider } from './Firebase.js';
import { signInWithPopup } from 'firebase/auth';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import SERVER_URL from './components/URL.js';
import secretKey from './components/Secret.js';

function Login(props) {
  const [defaultCountry, setDefaultCountry] = useState(''); // Default country state
  const [isPhoneInput, setIsPhoneInput] = useState(false);
  const [isEmailInput, setIsEmailInput] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const [Token , setToken] = useState('')

  props.data(false)

  const navigate = useNavigate();

  



//  useEffect(function()
// {
//     Axios.get(`${SERVER_URL}/getkey`).then(function(output)
//   {
//      setToken(output.data)
//   }).catch(function(error)
// {
//     console.error(error)
// })
// })





  useEffect(() => {
    const fetchGeoIP = async () => {
      try {
        const response = await Axios.get('https://ipinfo.io');
        if (response && response.data && response.data.country) {
          const country = response.data.country.toLowerCase();
          setDefaultCountry(country); // Set default country based on IP
        } else {
          console.error('Failed to fetch GeoIP data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching GeoIP data:', error);
      }
    };
    fetchGeoIP(); // Initial fetch of GeoIP data on component mount
  },[]);

  const validationSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .test(
        'emailOrPhone',
        'Invalid email or phone number',
        value => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const phoneRegex = /^\d{5,15}$/;

          // Check if it's a valid email
          if (emailRegex.test(value)) {
            return true;
          }

          // Check if it's a valid phone number for the selected country
          const phoneNumber = parsePhoneNumberFromString(value, defaultCountry.toUpperCase());
          if (phoneNumber) {
            return phoneRegex.test(value) && phoneNumber.isValid();
          }

          return false;
        }
      )
      .required('Required'),
  });

  const handleLinkedInSignIn = () => {
    const linkedInClientId = '86twjp6r64jxz2';
    const linkedInRedirectUri = 'https://4n.eco/callback';
    const linkedInScope = 'r_liteprofile r_emailaddress';
    const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${linkedInClientId}&redirect_uri=${linkedInRedirectUri}&scope=${encodeURIComponent(linkedInScope)}`;
    window.location.href = linkedInAuthUrl;
  };

  const Google = () => {
    signInWithPopup(auth, myProvider)
      .then(() => {
        let username = auth.currentUser.displayName;
        let email = auth.currentUser.email;
        console.log(username, email);
        navigate('/home');
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className="login-container">
      <button onClick={handleLinkedInSignIn} className="linkedin-button">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <g id="LinkedIn">
            <path fill="#FFFFFF" d="M12 12H4V36H12zM8 4a4 4 0 100 8 4 4 0 000-8zM36 12h-8v4.78c0 .15 0 .3-.03.45-.24 1.11-.96 2.05-2.08 2.66V12h-8v24h8v-11.7c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5V36h8V20.5c0-4.56-3.94-8.5-8.5-8.5z" />
          </g>
        </svg>
        Sign in with LinkedIn
      </button>

      <button onClick={Google} style={{ marginBottom: '10px' }} type="button" className="google-button">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
          <g id="Google">
            <path fill="#4285F4" d="M45.38,20.86c0,-1.25 -0.11,-2.47 -0.31,-3.67H24v6.97h12.14c-0.52,2.78 -2.62,5.11 -5.55,6.23v5.16h8.92c5.23,-4.82 8.23,-11.95 8.23,-20.69z" />
            <path fill="#34A853" d="M24,48c7.47,0 13.71,-2.47 18.26,-6.7l-8.92,-6.94c-2.48,1.69 -5.64,2.68 -9.34,2.68 -7.17,0 -13.22,-4.86 -15.37,-11.41H0.94v5.66C5.49,42.24 14.25,48 24,48z" />
            <path fill="#FBBC05" d="M8.63,28.74c-0.5,-1.5 -0.75,-3.11 -0.75,-4.74s0.25,-3.24 0.75,-4.74V13.67H0.94C0.34,15.95 0,18.36 0,20.86s0.34,4.91 0.94,7.19L8.63,28.74z" />
            <path fill="#EA4335" d="M24,9.24c3.22,0 6.14,1.1 8.46,2.92l6.33,-6.33C37.71,2.47 31.47,0 24,0 14.25,0 5.49,5.76 0.94,13.67l8.69,6.75c2.14,-6.55 8.2,-11.41 15.37,-11.41z" />
            <path fill="none" d="M0,0h48v48h-48z" />
          </g>
        </svg>
        Sign in with Google
      </button>

      <Formik
        initialValues={{ emailOrPhone: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const containsAlphabets = /[a-zA-Z]/.test(values.emailOrPhone);
          const containsOnlyDigits = /^\d+$/.test(values.emailOrPhone);

          

          const encodedValue = CryptoJS.AES.encrypt(values.emailOrPhone, secretKey).toString();
          const urlEncodedValue = encodeURIComponent(encodedValue);
          
          console.log(urlEncodedValue)
        
          if (emailRegex.test(values.emailOrPhone) && values.emailOrPhone.endsWith('.com')) {
            
            
            window.location.pathname = `/login/Email/${urlEncodedValue}`;
          } 
          
          else if (containsOnlyDigits) {
            window.location.pathname = `/login/OTP/${urlEncodedValue}`;
          } 
          
        }}
        
        validate={(values) => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          const phoneNumber = parsePhoneNumberFromString(values.emailOrPhone, defaultCountry.toUpperCase());
          const isValid = emailRegex.test(values.emailOrPhone) || (phoneNumber && phoneNumber.isValid());
          setIsValid(isValid);
        }}
      >
        {({ setFieldValue }) => (
          <Form method='post'>
            <div className="input-group">
              <Field name="emailOrPhone">
                {({ field }) => (
                  isPhoneInput ? (
                    <div className="phone-input">
                      <PhoneInput
                        id='phonelogin'
                        country={defaultCountry || 'auto'}
                        value={field.value}
                        onChange={(phone) => {
                          setFieldValue('emailOrPhone', phone);
                          setIsPhoneInput(/^\d/.test(phone));
                        }}
                        inputProps={{
                          name: 'emailOrPhone',
                          required: true,
                          autoFocus: true,
                        }}
                        dropdownClass="custom-dropdown"
                        placeholder="Enter phone number"
                        enableSearch
                      />
                    </div>
                  ) : (
                    <div className="email-wrapper">
                      <input
                        type="text"
                        className="input-box"
                        placeholder="Email"
                        {...field}
                        name='emailOrPhone'
                        onChange={(e) => {
                          setFieldValue('emailOrPhone', e.target.value);
                          setIsPhoneInput(/^\d/.test(e.target.value));
                          const containsAlphabets = /[a-zA-Z]/.test(e.target.value);
                          setIsEmailInput(containsAlphabets);
                        }}
                        required
                      />
                      {isEmailInput && <FaEnvelope className="email-icon" />}
                    </div>
                  )
                )}
              </Field>
              <ErrorMessage name="emailOrPhone" component="div" className="error-message" id='Login-error' />
            </div>
            {isValid ? (
              <button type="submit" className="btn">Next</button>
            ) : (
              <button type="submit" className="btn" disabled>Next</button>
            )}
          </Form>
        )}
      </Formik>

      <Link style={{textDecoration:'none'}} to='/register'><button className="btn">Create an account</button></Link>
    </div>
  );
}

export default Login;
