import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import SERVER_URL from '../components/URL';
import './Products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function Products(props) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    Axios.get(`${SERVER_URL}/Products`)
      .then(response => {
        console.log(response.data)
        setProducts(response.data);
      })
      .catch(error => {
        console.error('Error fetching products:', error);
      });
  }, []);

  return (
    <div>
      <h3 className={props.gender}>{props.gender} Wear</h3>
      <div className="products-container">
        {products.map((product, index) => (
          product.gender === props.gender && (
            <div key={index} className="product-card">
              <img height="300px" width="500px" src={product.imagePath} alt={product.description} className="product-image" />
              <div className='designname'>
                {/* Add design name or other details */}
              </div>
              <div className='group'>
                <div className='manuname'>
                  {/* Add manufacturer name or other details */}
                </div>
                <div className='pricebox'>
                  <p className="product-price">${product.price.toFixed(2)}</p>
                </div>
                <FontAwesomeIcon icon={faHeart} className='Hearticon' />
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='shareicon' />
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );
}

export default Products;
