import React from 'react'
import {Link} from 'react-router-dom'
import './Navbar.css'
import Axios from 'axios'
import SERVER_URL from './components/URL'

function Navbar(props) {


 function LogoutSession()
 {
  props.data(false)
    Axios.post(`${SERVER_URL}/logout`).then(function(output)
  {
      console.log("Done successfully")
  }).catch(function(error)
  {
     console.error(error)
  })
 }


  return (
    <div >



<nav class="navbar navbar-dark bg-dark">


<h1 style={{color : 'white'}} >TEEEVO</h1>
  
<Link  className='hcj' ><button type="button" class="btn btn-primary">HCJ</button></Link>


<Link  className='kan' ><button type="button" class="btn btn-primary">KAN</button></Link>


<Link className='span' ><button type="button" class="btn btn-primary">SPAN</button></Link>


<div  className='Logout'>

<Link onClick={LogoutSession}  to='/login' ><button type="button" class="btn btn-primary">Logout</button></Link>

</div>


</nav>






    </div>
  )
}

export default Navbar