// Import the functions you need from the SDKs you need
import { initializeApp  } from "firebase/app";
import myFirebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/compat/firestore"
import { getAuth , RecaptchaVerifier , signInWithPhoneNumber , GoogleAuthProvider} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCjhTtIQ0pRwAA0OfDb4RrEus0LerTVueI",
  authDomain: "urlshortener-c0b54.firebaseapp.com",
  projectId: "urlshortener-c0b54",
  storageBucket: "urlshortener-c0b54.appspot.com",
  messagingSenderId: "379423232765",
  appId: "1:379423232765:web:c269c5b925bf5f57656494",
  measurementId: "G-T6HBDD2RN1"
};

const app = myFirebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

auth.languageCode = 'en'; // Set default language

export const myProvider = new GoogleAuthProvider()//Google Authentication in Firebase

export { auth, RecaptchaVerifier, signInWithPhoneNumber};

