




// import React, { useEffect, useState } from 'react';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import PhoneInput from 'react-phone-number-input';
// import 'react-phone-number-input/style.css';
// import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import './App.css';
// import Axios from 'axios';
// import SERVER_URL from './components/URL';
// import { format } from 'date-fns';

// const RegisterForm = () => {
//   const validationSchema = Yup.object().shape({

//     email: Yup.string()
//       .email('Please enter a valid email address.')
//       .required('Email is required.'),
//     password: Yup.string()
//       .min(8, 'Password must be at least 8 characters.')
//       .matches(
//         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
//         'Password requires: uppercase, lowercase, digit, special character.'
//       )
//       .required('Password is required.'),
//     confirmPassword: Yup.string()
//       .oneOf([Yup.ref('password'), null], 'Passwords must match.')
//       .required('Please confirm your password.'),
//     countryCode: Yup.string()
//       .matches(/^\+\d+$/, 'Please enter a valid country code.')
//       .required('Country code is required.'),
//     phone: Yup.string()
//       .matches(/^\+?\d{5,15}$/, 'Please enter a valid phone number.')
//       .required('Phone number is required.'),
//   });

//   const [showPassword, setShowPassword] = useState(false);
//   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
//   const [defaultCountry, setDefaultCountry] = useState('US');



  

  

// // console.log(formattedDate); // June 26, 2024 08:10:15
 

  

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const toggleConfirmPasswordVisibility = () => {
//     setShowConfirmPassword(!showConfirmPassword);
//   };

//   useEffect(() => {
//     const fetchGeoIP = async () => {
//       try {
//         const response = await Axios.get('https://ipinfo.io');
//         if (response && response.data && response.data.country) {
//           const country = response.data.country.toUpperCase();
//           setDefaultCountry(country); // Set default country based on IP
//         } else {
//           console.error('Failed to fetch GeoIP data:', response.statusText);
//         }
//       } catch (error) {
//         console.error('Error fetching GeoIP data:', error);
//       }
//     };
//     fetchGeoIP(); // Initial fetch of GeoIP data on component mount
//   }, []);

//   const handleSubmit = async (values, { resetForm }) => {
//     // Handle form submission logic here, e.g., API calls
//     console.log(values);
    
//     Axios.post(`${SERVER_URL}/register`, values)
//       .then(function (output) {
//         console.log(output);

//         const { status, message, redirectUrl } = output.data;
//         alert(message);
//         window.location.href = redirectUrl;
//       })
//       .catch(function (error) {
//         console.error(error);
//       });

//     setShowConfirmPassword(false);
//     setShowPassword(false);
//     resetForm();
//   };

//   return (
//     <div className='register-form-container'>
//       <h2>Register</h2>
//       <Formik
//         initialValues={{
          
//           email: '',
//           password: '',
//           confirmPassword: '',
//           countryCode: '',
//           phone: '',
//         }}
//         validationSchema={validationSchema}
//         onSubmit={handleSubmit}
//       >
//         {({ values, setFieldValue, isSubmitting }) => (
//           <Form className='register-form'>
            
//             <div className='form-group'>
//               <label htmlFor="phone">Phone Number:</label>
//               <div className="phone-input-container">
//                 <PhoneInput
//                   international
//                   defaultCountry={defaultCountry}
//                   value={values.phone}
//                   onChange={(phone) => {
//                     setFieldValue('phone', phone);
//                     if (phone) {
//                       const phoneNumber = parsePhoneNumberFromString(phone);
//                       if (phoneNumber) {
//                         setFieldValue('countryCode', `+${phoneNumber.countryCallingCode}`);
//                       }
//                     } else {
//                       setFieldValue('countryCode', '');
//                     }
//                   }}

//                   id = 'phone-input'
//                   className="input-field"
//                 />
//               </div>
//               <ErrorMessage name="phone" component="div" className="error-message" />
//             </div>




//             <div className='form-group'>
//               <label htmlFor="email">Email:</label><br/>
//               <Field
//                 type="email"
//                 id="email"
//                 name="email"
//                 className="input-field"
//               />
//               <ErrorMessage name="email" component="div" className="error-message" />
//             </div>

//             <div className='form-group'>
//               <label htmlFor="password">Password:</label>
              
//               <div className='both'>
//                 <Field
//                   type={showPassword ? 'text' : 'password'}
//                   id="password"
//                   name="password"
//                   className="input-field"
//                 />
//                 <span onClick={togglePasswordVisibility}>
//                   <i id='Eye' style={{ color: showPassword ? 'red' : 'gray' }} className="fa fa-eye"></i>
//                 </span>
//               </div>
//               <ErrorMessage name="password" component="div" className="error-message" />
//             </div>

//             <div className='form-group'>
//               <label htmlFor="confirmPassword">Confirm Password:</label>
//               <div className='both'>
//                 <Field
//                   type={showConfirmPassword ? 'text' : 'password'}
//                   id="confirmPassword"
//                   name="confirmPassword"
//                   className="input-field"
//                 />
//                 <span onClick={toggleConfirmPasswordVisibility}>
//                   <i id='Eye' style={{ color: showConfirmPassword ? 'red' : 'gray' }} className="fa fa-eye"></i>
//                 </span>
//                 {values.password === values.confirmPassword && values.confirmPassword !== '' && (
//                   <span className="tick-icon">
//                     <i className="fa fa-check" id='tick' style={{ color: 'green' }}></i>
//                   </span>
//                 )}
//               </div>
//               <ErrorMessage name="confirmPassword" component="div" className="error-message" />
//             </div>

            

//             <button type="submit" className="submit-btn" disabled={isSubmitting}>
//               Register
//             </button>
//           </Form>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default RegisterForm;







import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './App.css';
import Axios from 'axios';
import SERVER_URL from './components/URL';
import { format } from 'date-fns';


const RegisterForm = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address.'),
    phone: Yup.string()
      .matches(/^\+?\d{5,15}$/, 'Please enter a valid phone number.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        'Password requires: uppercase, lowercase, digit, special character.'
      )
      .required('Password is required.'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .required('Please confirm your password.'),
    countryCode: Yup.string()
      .matches(/^\+\d+$/, 'Please enter a valid country code.'),
  }).test(
    'email-or-phone',
    'Either email or phone number is required.',
    function (value) {
      return value.email || value.phone;
    }
  );




  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState('US');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const fetchGeoIP = async () => {
      try {
        const response = await Axios.get('https://ipinfo.io');
        if (response && response.data && response.data.country) {
          const country = response.data.country.toUpperCase();
          setDefaultCountry(country); // Set default country based on IP
        } else {
          console.error('Failed to fetch GeoIP data:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching GeoIP data:', error);
      }
    };
    fetchGeoIP(); // Initial fetch of GeoIP data on component mount
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    // Handle form submission logic here, e.g., API calls
    console.log(values);
    
    Axios.post(`${SERVER_URL}/Register`, values)
      .then(function (output) {
        console.log(output);

        const { status, message, redirectUrl } = output.data;
        alert(message);
        window.location.href = redirectUrl;
      })
      .catch(function (error) {
        console.error(error);
      });

    setShowConfirmPassword(false);
    setShowPassword(false);
    resetForm();
  };

  return (
    <div className='register-form-container'>
      <h2>Register</h2>
      <Formik
        initialValues={{
          email: '',
          password: '',
          confirmPassword: '',
          countryCode: '',
          phone: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className='register-form'>
            <div className='form-group'>
              <label htmlFor="phone">Phone Number:</label>
              <div className="phone-input-container">
                <PhoneInput
                  international
                  defaultCountry={defaultCountry}
                  value={values.phone}
                  onChange={(phone) => {
                    setFieldValue('phone', phone);
                    if (phone) {
                      const phoneNumber = parsePhoneNumberFromString(phone);
                      if (phoneNumber) {
                        setFieldValue('countryCode', `+${phoneNumber.countryCallingCode}`);
                      }
                    } else {
                      setFieldValue('countryCode', '');
                    }
                  }}
                  id='phone-input'
                  className="input-field"
                />
              </div>
              <ErrorMessage name="phone" component="div" className="error-message" />
            </div>

            <div className='form-group'>
              <label htmlFor="email">Email:</label><br/>
              <Field
                type="email"
                id="email"
                name="email"
                className="input-field"
              />
              <ErrorMessage name="email" component="div" className="error-message" />
            </div>

            <div className='form-group'>
              <label htmlFor="password">Password:</label>
              <div className='both'>
                <Field
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  className="input-field"
                />
                <span onClick={togglePasswordVisibility}>
                  <i id='Eye' style={{ color: showPassword ? 'red' : 'gray' }} className="fa fa-eye"></i>
                </span>
              </div>
              <ErrorMessage name="password" component="div" className="error-message" />
            </div>

            <div className='form-group'>
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <div className='both'>
                <Field
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="input-field"
                />
                <span onClick={toggleConfirmPasswordVisibility}>
                  <i id='Eye' style={{ color: showConfirmPassword ? 'red' : 'gray' }} className="fa fa-eye"></i>
                </span>
                {values.password === values.confirmPassword && values.confirmPassword !== '' && (
                  <span className="tick-icon">
                    <i className="fa fa-check" id='tick' style={{ color: 'green' }}></i>
                  </span>
                )}
              </div>
              <ErrorMessage name="confirmPassword" component="div" className="error-message" />
            </div>

            <button type="submit" className="submit-btn" disabled={isSubmitting}>
              Register
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterForm;

